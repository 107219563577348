import { useState, useEffect } from "react"
import * as yup from "yup"

function NewsLetterFormBuilder(values) {
  const fileSchema = yup.array().of(yup.object()).nullable()
  const [schema, setSchema] = useState({})

  useEffect(() => {
    const schema = {
      full_name: {
        label: "Full Name",
        type: "text",
        required: true,
      },

      email: {
        label: "Email",
        type: "email",
        required: true,
      },
      phone_number: {
        label: "Phone Number",
        type: "text",
        // schema: yup.number().min(10, "Mobile Number should have 10 digits"),
      },
      company_name: {
        label: "Company Name",
        type: "text",
      },
      designation: {
        label: "Job Title",
        type: "text",
      },
      remarks: {
        label: "Any Remarks",
        type: "text",
      },
    }
    setSchema(schema)
  }, [values])

  return schema
}

export default NewsLetterFormBuilder
