import React from "react"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { Form } from "skillstrainer-resource-library"
import NewsLetterFormBuilder from "../formBuilder/news-letter-form-builder"

export default function NewsLetter() {
  const handleSubmit = async register => {
    let payload
    console.log("register===", register)
    payload = {
      data: {
        entity: {
          type: "user-interest",
          eventId: 1,
        },
        operation: "insert",

        data: {
          form_type: "newsletter",
          full_name: register.full_name,
          designation: register.designation,
          company_name: register.company_name,
          email: register.email,
          phone_number: register.phone_number,
          remarks: register.remarks,
          country: "not required",
        },
      },
    }

    if (
      payload.data.data.company_name === undefined ||
      payload.data.data.company_name === ""
    ) {
      payload.data.data.company_name = "not required"
    }

    if (
      payload.data.data.phone_number === undefined ||
      payload.data.data.phone_number === ""
    ) {
      payload.data.data.phone_number = "1234567890"
    }

    if (
      payload.data.data.designation === undefined ||
      payload.data.data.designation === ""
    ) {
      payload.data.data.designation = "not required"
    }

    try {
      const result = await axios.post(
        `${process.env.URL}/api/user-interests`,
        payload
      )

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err.response.data.error.message)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }

  return (
    <>
      <div className="pt-3 mt-4 px-0 px-sm-0 px-md-5 px-lg-5 px-xl-5 px-xxl-5">
        <ToastContainer />
        <h1 className="heading mb-3">SIGNUP FOR OUR NEWSLETTER</h1>

        <Form
          formBuilder={NewsLetterFormBuilder}
          className="grid grid-cols-2 gap-x-2"
          submitButton={{
            text: "Save",
            className: "btn learn-more-btn",
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  )
}
