/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap"
import React from "react"
import Header from "./src/components/header"
import Footer from "./src/components/footer"
import "animate.css/animate.min.css"
import "boxicons"
// import "skillstrainer-resource-library/css/styles.css"

require("react-toastify/dist/ReactToastify.css")

require("bootstrap/dist/css/bootstrap.css")
require("bootstrap/dist/js/bootstrap")

export function wrapPageElement({ element, props }) {
  return (
    <>
      {/* <div id="page-container" className=""> */}
      <Header />

      {element}
      <Footer />

      {/* </footer> */}
      {/* </div> */}
    </>
  )
}
