// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-indian-esg-network-js": () => import("./../../../src/pages/about-indian-esg-network.js" /* webpackChunkName: "component---src-pages-about-indian-esg-network-js" */),
  "component---src-pages-advisors-js": () => import("./../../../src/pages/advisors.js" /* webpackChunkName: "component---src-pages-advisors-js" */),
  "component---src-pages-attend-program-js": () => import("./../../../src/pages/attendProgram.js" /* webpackChunkName: "component---src-pages-attend-program-js" */),
  "component---src-pages-auroville-programs-js": () => import("./../../../src/pages/auroville-programs.js" /* webpackChunkName: "component---src-pages-auroville-programs-js" */),
  "component---src-pages-awards-2023-js": () => import("./../../../src/pages/awards2023.js" /* webpackChunkName: "component---src-pages-awards-2023-js" */),
  "component---src-pages-awards-js": () => import("./../../../src/pages/awards.js" /* webpackChunkName: "component---src-pages-awards-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-counter-js": () => import("./../../../src/pages/counter.js" /* webpackChunkName: "component---src-pages-counter-js" */),
  "component---src-pages-courses-form-js": () => import("./../../../src/pages/courses-form.js" /* webpackChunkName: "component---src-pages-courses-form-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-delhi-programs-js": () => import("./../../../src/pages/delhi-programs.js" /* webpackChunkName: "component---src-pages-delhi-programs-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-esg-network-launch-js": () => import("./../../../src/pages/esg-network-launch.js" /* webpackChunkName: "component---src-pages-esg-network-launch-js" */),
  "component---src-pages-esg-training-js": () => import("./../../../src/pages/esg-training.js" /* webpackChunkName: "component---src-pages-esg-training-js" */),
  "component---src-pages-event-form-js": () => import("./../../../src/pages/event-form.js" /* webpackChunkName: "component---src-pages-event-form-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-get-data-js": () => import("./../../../src/pages/get-data.js" /* webpackChunkName: "component---src-pages-get-data-js" */),
  "component---src-pages-greatings-js": () => import("./../../../src/pages/greatings.js" /* webpackChunkName: "component---src-pages-greatings-js" */),
  "component---src-pages-hero-js": () => import("./../../../src/pages/hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-iesg-network-launch-js": () => import("./../../../src/pages/IESG-network-launch.js" /* webpackChunkName: "component---src-pages-iesg-network-launch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indian-esg-team-js": () => import("./../../../src/pages/indian-esg-team.js" /* webpackChunkName: "component---src-pages-indian-esg-team-js" */),
  "component---src-pages-indo-russia-registration-form-js": () => import("./../../../src/pages/indo-russia-registration-form.js" /* webpackChunkName: "component---src-pages-indo-russia-registration-form-js" */),
  "component---src-pages-indo-russia-round-table-js": () => import("./../../../src/pages/indo-russia-round-table.js" /* webpackChunkName: "component---src-pages-indo-russia-round-table-js" */),
  "component---src-pages-join-our-community-form-js": () => import("./../../../src/pages/join-our-community-form.js" /* webpackChunkName: "component---src-pages-join-our-community-form-js" */),
  "component---src-pages-join-the-event-js": () => import("./../../../src/pages/join-the-event.js" /* webpackChunkName: "component---src-pages-join-the-event-js" */),
  "component---src-pages-knowledge-repository-building-a-sustainable-future-jsx": () => import("./../../../src/pages/knowledge_repository/building-a-sustainable-future.jsx" /* webpackChunkName: "component---src-pages-knowledge-repository-building-a-sustainable-future-jsx" */),
  "component---src-pages-knowledge-repository-coso-icsr-jsx": () => import("./../../../src/pages/knowledge_repository/coso-icsr.jsx" /* webpackChunkName: "component---src-pages-knowledge-repository-coso-icsr-jsx" */),
  "component---src-pages-knowledge-repository-js": () => import("./../../../src/pages/knowledge-repository.js" /* webpackChunkName: "component---src-pages-knowledge-repository-js" */),
  "component---src-pages-knowledge-repository-undp-climate-change-jsx": () => import("./../../../src/pages/knowledge_repository/undp_climate_change.jsx" /* webpackChunkName: "component---src-pages-knowledge-repository-undp-climate-change-jsx" */),
  "component---src-pages-media-coverage-js": () => import("./../../../src/pages/mediaCoverage.js" /* webpackChunkName: "component---src-pages-media-coverage-js" */),
  "component---src-pages-news-letter-form-js": () => import("./../../../src/pages/news-letter-form.js" /* webpackChunkName: "component---src-pages-news-letter-form-js" */),
  "component---src-pages-nominate-js": () => import("./../../../src/pages/nominate.js" /* webpackChunkName: "component---src-pages-nominate-js" */),
  "component---src-pages-nomination-complete-js": () => import("./../../../src/pages/nomination-complete.js" /* webpackChunkName: "component---src-pages-nomination-complete-js" */),
  "component---src-pages-nomination-test-js": () => import("./../../../src/pages/nomination_test.js" /* webpackChunkName: "component---src-pages-nomination-test-js" */),
  "component---src-pages-old-hero-js": () => import("./../../../src/pages/old-hero.js" /* webpackChunkName: "component---src-pages-old-hero-js" */),
  "component---src-pages-orientation-gallery-js": () => import("./../../../src/pages/orientation-gallery.js" /* webpackChunkName: "component---src-pages-orientation-gallery-js" */),
  "component---src-pages-orientation-program-js": () => import("./../../../src/pages/orientation-program.js" /* webpackChunkName: "component---src-pages-orientation-program-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-participate-js": () => import("./../../../src/pages/participate.js" /* webpackChunkName: "component---src-pages-participate-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photoGallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-publish-trigger-js": () => import("./../../../src/pages/publish-trigger.js" /* webpackChunkName: "component---src-pages-publish-trigger-js" */),
  "component---src-pages-query-form-js": () => import("./../../../src/pages/query-form.js" /* webpackChunkName: "component---src-pages-query-form-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-test-js": () => import("./../../../src/pages/register_test.js" /* webpackChunkName: "component---src-pages-register-test-js" */),
  "component---src-pages-registration-complete-js": () => import("./../../../src/pages/registration-complete.js" /* webpackChunkName: "component---src-pages-registration-complete-js" */),
  "component---src-pages-registration-data-js": () => import("./../../../src/pages/registrationData.js" /* webpackChunkName: "component---src-pages-registration-data-js" */),
  "component---src-pages-social-media-repository-instagram-feed-jsx": () => import("./../../../src/pages/social_media_repository/instagramFeed.jsx" /* webpackChunkName: "component---src-pages-social-media-repository-instagram-feed-jsx" */),
  "component---src-pages-speaker-form-js": () => import("./../../../src/pages/speaker-form.js" /* webpackChunkName: "component---src-pages-speaker-form-js" */),
  "component---src-pages-speaker-js": () => import("./../../../src/pages/speaker.js" /* webpackChunkName: "component---src-pages-speaker-js" */),
  "component---src-pages-sponsor-form-js": () => import("./../../../src/pages/sponsor-form.js" /* webpackChunkName: "component---src-pages-sponsor-form-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-training-form-js": () => import("./../../../src/pages/training-form.js" /* webpackChunkName: "component---src-pages-training-form-js" */),
  "component---src-pages-training-programs-js": () => import("./../../../src/pages/training-programs.js" /* webpackChunkName: "component---src-pages-training-programs-js" */),
  "component---src-pages-upcoming-events-js": () => import("./../../../src/pages/upcoming-events.js" /* webpackChunkName: "component---src-pages-upcoming-events-js" */),
  "component---src-pages-user-interest-js": () => import("./../../../src/pages/user-interest.js" /* webpackChunkName: "component---src-pages-user-interest-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-webinar-js": () => import("./../../../src/pages/webinar.js" /* webpackChunkName: "component---src-pages-webinar-js" */),
  "component---src-templates-courses-des-js": () => import("./../../../src/templates/courses-des.js" /* webpackChunkName: "component---src-templates-courses-des-js" */)
}

