import React from "react"
import { useForm } from "react-hook-form"
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"

export default function QueryForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  })
  const onSubmit = async queryForm => {
    const payload = {
      data: {
        entity: {
          type: "query",
          eventId: 1,
        },
        operation: "insert",
        data: {
          full_name: queryForm.full_name,
          company_name: queryForm.company_name,
          email: queryForm.email,
          description: queryForm.query,
        },
      },
    }

    try {
      const result = await axios.post(`${process.env.URL}/api/queries`, payload)

      if (result.status === 200) {
        return toast.success("Success", {
          position: toast.POSITION.TOP_CENTER,
        })
      } else {
        toast.error("Something gone wrong!", {
          position: toast.POSITION.TOP_CENTER,
        })
      }
    } catch (err) {
      console.log("I am error", err)
      return toast.error("Something gone wrong!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }
  return (
    <>
      <h3 className=" text-white fw-bold">CONTACT US</h3>
      <ToastContainer />

      <form className="px-0" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <input
              placeholder="Your Name"
              className="form-control mt-4 query-input"
              type="text"
              {...register("full_name", {
                required: true,
                minLength: 4,
              })}
            />
            {errors.full_name && (
              <p className="text-danger">Please Fill the Name </p>
            )}
          </div>
          <div className="col-12 col-md-6">
            <input
              placeholder="Your email"
              className="form-control mt-4 query-input"
              type="text"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && (
              <p className="text-danger">Please check the Email</p>
            )}
          </div>

          <div className="col-12 col-md-6">
            <input
              className="form-control mt-4 query-input"
              placeholder="Your company name"
              type="text"
              {...register("company_name", {
                required: true,
                minLength: 4,
              })}
            />
            {errors.company_name && (
              <p className="text-danger">Please Fill the Company Name</p>
            )}
          </div>
          <div className="col-12 col-md-6 ">
            <textarea
              className="form-control mt-4 query-input"
              rows={1}
              placeholder="Your query"
              {...register("query", {
                required: true,
                minLength: 10,
              })}
            />
            {errors.query && (
              <p className="text-danger">Please Fill the Query</p>
            )}
          </div>
          <div className="col-md-12 text-start">
            <button className="btn submit-query-btn mt-4" type="submit">
              SUBMIT
            </button>
          </div>
          <div className="col-md-12"></div>
        </div>
      </form>
    </>
  )
}
